import { CoffeeSdkWrapper } from '@/api/coffeeApi/sdkWrapper.js';

class ReferralService extends CoffeeSdkWrapper {
  constructor() {
    super();
  }

  async getReferralInfo(address, verification) {
    const xVerify = JSON.stringify(verification);
    return await this.referralApi.getReferralInfo(address, xVerify);
  }

  async bindReferralLink(address, refAddress, verification) {
    const xVerify = JSON.stringify(verification);
    return await this.referralApi.bindReferral(address, refAddress, xVerify)
  }

  async getReferralList(address, verification, opts) {
    const xVerify = JSON.stringify(verification);

    let page = 1;
    let size = 10;
    if (opts) {
      page = opts.page;
      size = opts.size;
    }

    return await this.referralApi.getReferralList(address, xVerify, size, page);
  }
}

export default new ReferralService();
