export default {
	state: () => ({
		slippage: 5,
		priceImpact: 10,
		maxPoolVolatility: -1,
		maxIntermediateTokens: 1,
		isExpertMode: false,
		maxSplitsValue: 4,
		isChartEnabled: true,
		isDevModeEnabled: false
	}),
	mutations: {
		SAVE_SLIPPAGE(state, item) {
			state.slippage = item
		},
		SAVE_PRICE_IMPACT(state, item) {
			state.priceImpact = item
		},
		SAVE_MAX_POOL_VOLATILITY(state, item) {
			state.maxPoolVolatility = item
		},
		SAVE_MAX_INTERMEDIATE_TOKENS(state, item) {
			state.maxIntermediateTokens = item
		},
		CLEAR_SETTINGS(state) {
			state.slippage = 5
			state.priceImpact = 10
			state.maxPoolVolatility = -1
			state.maxIntermediateTokens = 1
			state.maxSplitsValue = 4
		},
		CLEAR_EXPERTS_SETTINGS(state) {
			state.priceImpact = 10
			state.maxPoolVolatility = -1
			state.maxIntermediateTokens = 1
		},
		SAVE_EXPERT_MODE_VALUE(state, item) {
			state.isExpertMode = item
		},
		SAVE_MAX_SPLITS(state, item) {
			state.maxSplitsValue = item
		},
		SAVE_CHART_IS_ENABLED(state, item) {
			state.isChartEnabled = item
		},
		SAVE_DEV_MODE_VALUE(state, item) {
			state.isDevModeEnabled = item;
		}
	},
	actions: {
		DEX_SLIPPAGE({commit}, item) {
			commit('SAVE_SLIPPAGE', item)
		},
		DEX_PRICE_IMPACT({commit}, item) {
			commit('SAVE_PRICE_IMPACT', item)
		},
		DEX_MAX_POOL_VOLATILITY({commit}, item) {
			commit('SAVE_MAX_POOL_VOLATILITY', item)
		},
		DEX_MAX_INTERMEDIATE_TOKENS({commit}, item) {
			commit('SAVE_MAX_INTERMEDIATE_TOKENS', item)
		},
		CLEAR_DEX_SETTINGS({commit}) {
			commit('CLEAR_SETTINGS');
		},
		CLEAR_DEX_EXPERTS_SETTINGS({ commit }) {
			commit('CLEAR_EXPERTS_SETTINGS');
		},
		DEX_EXPERT_MODE({ commit }, item) {
			commit('SAVE_EXPERT_MODE_VALUE', item);
		},
		DEX_MAX_SPLITS({ commit }, item) {
			commit('SAVE_MAX_SPLITS', item)
		},
		DEX_SHOW_CHART_SETTING({commit}, item) {
			commit('SAVE_CHART_IS_ENABLED', item)
		},
		DEX_DEV_MODE({commit}, item) {
			commit('SAVE_DEV_MODE_VALUE', item)
		}
	},
	getters: {
		GET_SLIPPAGE: state => {
			return state.slippage
		},
		GET_PRICE_IMPACT: state => {
			return state.priceImpact
		},
		GET_MAX_POOL_VOLATILITY: state => {
			return state.maxPoolVolatility
		},
		GET_MAX_INTERMEDIATE_TOKENS: state => {
			return state.maxIntermediateTokens
		},
		GET_EXPERT_MODE_VALUE: state => {
			return state.isExpertMode
		},
		GET_MAX_SPLITS: state => {
			return state.maxSplitsValue
		},
		GET_CHART_VISIBLE_SETTING: state => {
			return state.isChartEnabled
		},
		GET_DEV_MODE_SETTING: state => {
			return state.isDevModeEnabled
		}
	}
}
