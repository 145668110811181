class GeoService {
  constructor() {
    this.baseUrl = 'https://ipapi.co/json/';
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
  }

  async getUserCountryCode() {
    try {
      const response = await fetch(this.baseUrl, {
        headers: this.defaultHeaders,
      });

      const data = await response.json();
      return data?.country_code;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new GeoService();
