import { CoffeeSdkWrapper } from '@/api/coffeeApi/sdkWrapper.js';

class CashbackService extends CoffeeSdkWrapper {
  constructor() {
    super();
  }
  async getCashbackList() {
    return await this.cashbackApi.getCashbackInfos(null, null, null, 100);;
  }

  async getCashbackItem(id) {
    return await this.cashbackApi.getCashbackInfo(id);
  }
}

export default new CashbackService();
