import { CoffeeSdkWrapper } from '@/api/coffeeApi/sdkWrapper.js';

class ClaimService extends CoffeeSdkWrapper {
  constructor() {
    super();
  }

  async getClaimInfo(address, type, verification, opts) {
    let page = 1;
    let size = 50;

    if (opts) {
      page = opts.page;
      size = opts.size;
    }
    const xVerify = JSON.stringify(verification);

    return await this.claimApi.getClaimingStats(address, xVerify, type, size, page);
  }

  async claimTokens(address, type, tokens, verification) {
    const xVerify = JSON.stringify(verification);
    return await this.claimApi.claimTokens(address, xVerify, type, tokens);
  }

  async getClaimHistory(address, type, verification, opts) {
    const xVerify = JSON.stringify(verification);
    let page = 1;
    let size = 10;

    if (opts) {
      page = opts.page;
      size = opts.size;
    }

    return await this.claimApi.getAccountClaimingState(address, xVerify, type, size, page);
  }

  async getClaimStatus(idList) {
    return await this.claimApi.getClaimingTransactionResult(idList);
  }

}

export default new ClaimService();
