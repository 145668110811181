import {
  ClaimApi,
  RoutingApi,
  StakingApi,
  TonApi,
  CashbackApi,
  ContestsApi,
  ReferralApi,
  EntityApi,
  ProfileApi
} from '@swap-coffee/sdk';

class CoffeeSdkWrapper {
  constructor() {
    // this.routingApi = new RoutingApi({ basePath: "http://localhost:8080" });
    this.routingApi = new RoutingApi();
    this.tonApi = new TonApi();
    this.stakingApi = new StakingApi();
    this.claimApi = new ClaimApi();
    this.cashbackApi = new CashbackApi();
    this.contestApi = new ContestsApi();
    this.referralApi = new ReferralApi();
    this.tokenApi = new EntityApi();
    this.profileApi = new ProfileApi();
  }
}

export { CoffeeSdkWrapper };
