import { CoffeeSdkWrapper } from '@/api/coffeeApi/sdkWrapper.js';

class StakingService extends CoffeeSdkWrapper {
  constructor() {
    super();
  }
  async getStakingGlobalInfo(masterAddress) {
    return await this.stakingApi.getStakingGlobalInfo(masterAddress)
  }

  async getStakingUserInfo(address, verification, masterAddress) {
    const xVerify = JSON.stringify(verification);
    return await this.stakingApi.getStakingUserInfo(masterAddress, address, xVerify);
  }

  async createStakingPosition(data, masterAddress) {
    const { address, assetAddress, amountToStakeRaw, periodId, verification } = data;

    const xVerify = JSON.stringify(verification);

    const body = {
      asset_address: assetAddress,
      amount_to_stake_raw: amountToStakeRaw,
      period_id: periodId
    };

    return await this.stakingApi.createStakingPosition(masterAddress, address, xVerify, body);
  }


  async extendStakingPosition(address, positionId, periodId, verification, masterAddress) {
    const xVerify = JSON.stringify(verification);

    const body = {
      period_id: periodId
    };

    return await this.stakingApi.extendStakingPosition(masterAddress, address, positionId, xVerify, body);
  }

  async closeStakingPosition(address, positionId, verification, masterAddress) {
    const xVerify = JSON.stringify(verification);
    return await this.stakingApi.closeStakingPosition(masterAddress, address, positionId, xVerify);
  }

  async getStakingTransactionStatus(query_id) {
    return await this.stakingApi.getStakingTransactionResult(query_id);
  }

  async getStakingTokenBalance(address, verification, masterAddress) {
    const xVerify = JSON.stringify(verification);
    return await this.stakingApi.getStakingTokenBalances(masterAddress, address, xVerify);
  }

  async getStakingCatalogueList() {
    return await this.stakingApi.getAllStakings();
  }
}

export default new StakingService();
