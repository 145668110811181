import SnowflakeApi from '@/api/snowflakeApi.js';

export default {
  computed: {
    getRouteName() {
      return this.$route.name;
    },
    snowflakeApi() {
      return new SnowflakeApi();
    },
    getTrimAddress() {
      let address = this.GET_DEX_WALLET?.userFriendlyAddress;
      if (address) {
        let stringLength = 4;
        let firstHalf = address.substring(0, stringLength);
        let secondHalf = address.substring(address.length - stringLength);
        return firstHalf + '...' + secondHalf;
      } else {
        return '';
      }
    },
    formattedSnowflakeProof() {
      let proof = this.GET_PROOF_VERIFICATION
      let asset = {
        "address": this.GET_DEX_WALLET?.userFriendlyAddress,
        "publicKey": proof?.public_key,
        "walletStateInit": proof?.wallet_state_init,
        "proof": {
          "timestamp": proof?.proof.timestamp,
          "domain": {
            "lengthBytes": proof?.proof.domain_len,
            "value": proof?.proof.domain_val
          },
          "payload": proof?.proof.payload,
          "signature": proof?.proof.signature
        }
      }
      return asset
    },
  },
};
