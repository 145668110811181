import { CoffeeSdkWrapper } from '@/api/coffeeApi/sdkWrapper.js';

class ContestService extends CoffeeSdkWrapper {
  constructor() {
    super();
  }
  async getContests() {
    return await this.contestApi.getContests();
  }

  async getContestById(id) {
    return await this.contestApi.getContest(id);
  }

  async getTopForContest(id) {
    return await this.contestApi.getContestTopUsersStats(id);
  }

  async getUserStatsForContest(id, address, verification) {
    const xVerify = JSON.stringify(verification);
    return await this.contestApi.getContestUserStats(id, address, xVerify);
  }
}

export default new ContestService();
