export default class SnowflakeApi {
  constructor() {
    this.baseURL = 'https://snowflake.swap.coffee/v1';
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
  }

  async request(endpoint, body = null, method = 'GET', extraHeaders = {}) {
    const url = `${this.baseURL}${endpoint}`;
    const headers = {
      ...this.defaultHeaders,
      ...extraHeaders,
    };

    const config = {
      method,
      headers,
    };

    if (body) {
      config.body = JSON.stringify(body);
    }

    const response = await fetch(url, config);
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Request failed with status ${response.status}:\n${errorText}`);
    }

    const text = await response.text();

    try {
      return JSON.parse(text);
    } catch (error) {
      return text;
    }
  }

  async requestWithHeaders(endpoint, body = null, headers = {}, method = 'GET') {
    return this.request(endpoint, body, method, headers);
  }

  async getToken(proof) {
    let response = await this.request('/token', proof, 'POST');
    return response.token;
  }

  getPoints(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.requestWithHeaders('/points', null, headers);
  }

  incrementPoints(amount, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.requestWithHeaders('/points/increment', { amount }, headers, 'POST');
  }
}
