import { CoffeeSdkWrapper } from '@/api/coffeeApi/sdkWrapper.js';

class ProfileService extends CoffeeSdkWrapper {
  constructor() {
    super();
  }

  async readStorage(address, verification) {
    const xVerify = JSON.stringify(verification);
    return await this.profileApi.getAccountSettings(address, xVerify);
  }

  async writeStorage(address, verification, body) {
    const xVerify = JSON.stringify(verification);
    return await this.profileApi.updateAccountSettings(address, xVerify, body);
  }

  async getTransactionsHistory(address, tokens, verification) {
    const xVerify = JSON.stringify(verification);

    let firstTokenAddress = null;
    let secondTokenAddress = null;

    if (tokens.first) {
      firstTokenAddress = tokens?.first;
    }
    if (tokens.second) {
      secondTokenAddress = tokens?.second;
    }

    return await this.profileApi.getHistoricalTransactions(address, xVerify, firstTokenAddress, secondTokenAddress)
  }
}

export default new ProfileService();
